<template>
<div class="footer bg-gray"  style="background:url(&quot;/assets/images/ser-2-bg.png&quot;) ;">
   <img class="decoration-circles"  />
   <div class="container">
       <div class="row">
           <div class="col-lg-12">
               <h4>Kami adalah solusi praktis pengelolaan pajak anda yang mendukung bisnis anda</h4>
               <div class="social-container">
                   <span class="fa-stack">
                       <a href="https://maps.app.goo.gl/9v3RNpRa5EDeyovv6">
                           <i class="fas fa-circle fa-stack-2x"></i>
                           <i class="fa fa-map fa-stack-1x"></i>
                       </a>
                    
                   </span>
                   <span class="fa-stack">
                       <a href="mailto:consultaxion.group@gmail.com">
                           <i class="fas fa-circle fa-stack-2x"></i>
                           <i class="fa fa-envelope fa-stack-1x"></i>
                       </a>
                   </span>
                 
                   <span class="fa-stack">
                       <a href="https://www.instagram.com/consultaxion.id">
                           <i class="fas fa-circle fa-stack-2x"></i>
                           <i class="fab fa-instagram fa-stack-1x"></i>
                       </a>
                   </span>
                   <span class="fa-stack">
                       <a href="https://wa.link/qb58k6">
                           <i class="fas fa-circle fa-stack-2x"></i>
                           <i class="fab fa-whatsapp fa-stack-1x"></i>
                       </a>
                   </span>
                 
               </div> 
           </div> 
       </div>
   </div> 
 </div> 
 <div class="copyright bg-gray" style="background:url(&quot;/assets/images/ser-2-bg.png&quot;) ;">
   <div class="container">
       <div class="row">
           <div class="col-lg-6 col-md-12 col-sm-12">
             
           </div> 
           <div class="col-lg-6 col-md-12 col-sm-12">
               <p class="p-small statement">Copyright © <a href="#">consultaxion.id</a></p>
           </div> 
 
       </div> 
   </div> 
 </div>
 
 <button id="myBtn">
   <img src="/assets/images/up-arrow.png" alt="alternative" />
 </button>

</template>
<script>
  
    export default {
      name: 'FooterComponent',
    };
  </script>