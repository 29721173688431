<template>
    <div id="details" class="basic-1">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-xl-7">
                    <div class="image-container">
                        <img class="img-fluid" src="assets/images/details-1.png" alt="alternative" />
                    </div> 
                </div> 
                <div class="col-lg-6 col-xl-5">
                    <div class="text-container">
                       
                        <h2>Consultaxion</h2>
                        <h5>Melakukan kewajiban pajak dengan sukacita adalah tanda partisipasi aktif dalam membangun negara</h5>
                         <a class="btn-solid-reg" href="#details">Hubungi Kami</a>

                    </div> 
                </div> 
            </div>
        </div> 
    </div>
</template>
<script>
  
    export default {
      name: 'DetailsComponent',
    };
  </script>