<template>
    <div id="services" class="cards-1 bg-gray">
   <div class="container">
       <div class="row">
           <div class="col-lg-12">
               <h2>Layanan Kami</h2>
           </div> 
       </div>


       <div class="row">
           <div class="col-lg-12">
               
             
               <div class="card">
                   <div class="card-icon">
                       <span class="fas fa-list"></span>
                   </div>
                   <div class="card-body">
                       <h5 class="card-title">Layanan Pajak</h5>
                       <ul>
                            <li>
                            <p class="text-muted mt-2 mb-0">Konsultasi Pajak Online</p>
                            </li>
                            <li>
                            <p class="text-muted mt-2 mb-0">Pendaftaran NPWP</p>
                            </li>
                            <li>
                            <p class="text-muted mt-2 mb-0">Pembuatan EFIN</p>
                            </li>
                            <li>
                            <p class="text-muted mt-2 mb-0">Pengurusan PKP</p>
                            </li>
                            <li>
                            <p class="text-muted mt-2 mb-0">Pembayaran Pajak</p>
                            </li>
                            <li>
                            <p class="text-muted mt-2 mb-0">Kepatuhan Pajak Perorangan</p>
                            </li>
                            <li>
                            <p class="text-muted mt-2 mb-0">Kepatuhan Pajak Perusahaan</p>
                        </li>
                    </ul>
                   </div>
               </div>
           
               <div class="card">
                   <div class="card-icon red">
                       <span class="fas fa-clipboard"></span>
                   </div>
                   <div class="card-body">
                       <h5 class="card-title">Layanan Pendukung</h5>
                       <ul>
                            <li>
                            <p class="text-muted mt-2 mb-0">Pengurusan Izin Usaha</p>
                            </li>
                            <li>
                            <p class="text-muted mt-2 mb-0">Pembukuan Perusahaan</p>
                            </li>
                            <li>
                            <p class="text-muted mt-2 mb-0">Analisis Laporan Keuangan</p>
                            </li>
                            <li>
                            <p class="text-muted mt-2 mb-0">Reorganisasi Manajemen</p>
                            </li>
                            
                        </ul>
                   </div>
               </div>
             
               <div class="card">
                   <div class="card-icon green">
                       <span class="fas fa-comments"></span>
                   </div>
                   <div class="card-body">
                      
                       <div class="card-title">
                            <a class="btn-solid-lg" href="#details">Hubungi Kami</a>
                        </div>
                   </div>
               </div>
               
 
           </div>
       </div>
   </div>
 </div> 
</template>
<script>
  
    export default {
      name: 'ServicesComponent',
    };
  </script>