<template>
 
    <Tools/>
    <Footer/>
</template>

<script>
// @ is an alias to /src

import Tools from '@/components/ToolsAdmin.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'ToolsView',
  components: {
    Tools,Footer
  }
}
</script>
