<template>
  <br/>


    <div id="services" class="cards-1 bg-gray">
  
        <div class="container">
            
            
            <div class="row">

               <div class="col-lg-12">
               
             
               <div class="card">
                   <div class="card-icon blue">
                       <span class="fas fa-user-tie"></span>
                   </div>
                   <div class="card-body">
                       <h5 class="card-title">Uniform</h5>
                        <a class="read-more no-line" href="https://forms.clickup.com/3875495/f/3p8n7-115762/3WYMEYN1D27STHZPC7" target="_blank"><h6>Fitting<span class="fas fa-long-arrow-alt-right"></span></h6></a>
                        <a class="read-more no-line" href="https://doc.clickup.com/p/h/3p8n7-21285/d9894c1d929a4fb" target="_blank"><h6>Tech Draw<span class="fas fa-long-arrow-alt-right"></span></h6></a>
                        <a class="read-more no-line" href="https://doc.clickup.com/p/h/3p8n7-21305/92751bccc1af7b4" target="_blank"><h6>Creative Design<span class="fas fa-long-arrow-alt-right"></span></h6></a>
                        <a class="read-more no-line" href="https://pages.deskera.com/a/zw7vqnqsvv/landing_page/0f04770899dbf1734303334896540252.html?_ga=2.84180122.2053568031.1646807313-1212270549.1643856812%20(https://pages.deskera.com/a/zw7vqnqsvv/landing_page/0f04770899dbf1734303334896540252.html?_ga=2.84180122.2053568031.1646807313-1212270549.1643856812)" target="_blank"><h6>Estimate<span class="fas fa-long-arrow-alt-right"></span></h6></a>

                    </div>
               </div>
           
               <div class="card">
                   <div class="card-icon red">
                       <span class="fas fa-user"></span>
                   </div>
                   <div class="card-body">
                         <h5 class="card-title">Linen</h5>
                        <a class="read-more no-line" href="https://forms.clickup.com/f/3p8n7-58825/66YX6CTY3IE146BWMQ" target="_blank"><h6>Measurement<span class="fas fa-long-arrow-alt-right"></span></h6></a>
                        <a class="read-more no-line" href="https://doc.clickup.com/3875495/d/h/3p8n7-103625/f64e39764958afc" target="_blank"><h6>Bedding<span class="fas fa-long-arrow-alt-right"></span></h6></a>
                        <a class="read-more no-line" href="https://doc.clickup.com/d/h/3p8n7-76445/8366a0241835ed2" target="_blank"><h6>Bathroom<span class="fas fa-long-arrow-alt-right"></span></h6></a>
                        <a class="read-more no-line" href="https://pages.deskera.com/a/zw7vqnqsvv/landing_page/0f04770899dbf1734303334896540252.html?_ga=2.84180122.2053568031.1646807313-1212270549.1643856812%20(https://pages.deskera.com/a/zw7vqnqsvv/landing_page/0f04770899dbf1734303334896540252.html?_ga=2.84180122.2053568031.1646807313-1212270549.1643856812)" target="_blank"><h6>F&B<span class="fas fa-long-arrow-alt-right"></span></h6></a>

                    </div>
               </div>
             
               <div class="card">
                   <div class="card-icon green">
                       <span class="fas fa-users"></span>
                   </div>
                   <div class="card-body">

                    <div class="card-body">
                       <h5 class="card-title">Team</h5>
                        <a class="read-more no-line" href="https://pages.deskera.com/a/zw7vqnqsvv/landing_page/0f04770899dbf1734303334896540252.html?_ga=2.84180122.2053568031.1646807313-1212270549.1643856812%20(https://pages.deskera.com/a/zw7vqnqsvv/landing_page/0f04770899dbf1734303334896540252.html?_ga=2.84180122.2053568031.1646807313-1212270549.1643856812)" target="_blank"><h6>Request<span class="fas fa-long-arrow-alt-right"></span></h6></a>
                        <a class="read-more no-line" href="https://pages.deskera.com/a/zw7vqnqsvv/landing_page/0f04770899dbf1734303334896540252.html?_ga=2.84180122.2053568031.1646807313-1212270549.1643856812%20(https://pages.deskera.com/a/zw7vqnqsvv/landing_page/0f04770899dbf1734303334896540252.html?_ga=2.84180122.2053568031.1646807313-1212270549.1643856812)" target="_blank"><h6>Client Order<span class="fas fa-long-arrow-alt-right"></span></h6></a>
                      
                    </div>

                    </div>
               </div>
               
 
           </div>
              
            </div> 
           
            
           
        </div> 
      
       
    </div>

    


</template>
<script>
  
    export default {
      name: 'ToolsAdminComponent',
    };
    
</script>
