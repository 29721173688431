<template>
 
    <header id="header" class="header" style="background:url(&quot;/assets/images/lp-header.jpg&quot;) ;">
       

        <div class="container" >
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="title-heading text-center text-container">
                        <h1 class="h1-large" style="color:white;">Lets Start With Consultaxion</h1>
                        <h3 class="h3-large" style="color:white;">Kami memberi solusi terbaik perpajakan anda kapanpun dan dimanapun</h3>   
                        <div class="mt-4 pt-2">
                            <a class="btn-solid-lg" href="#services">Layanan Kami</a>
                        </div>

                    </div>
                </div><!--end col-->
            </div><!--end row-->
        </div>
       
    </header> 

    


</template>
<script>
  
    export default {
      name: 'MainComponent',
    };
    
</script>
