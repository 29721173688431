<template>
    <Navigation/>
    <router-view/>
</template>
<script>
// @ is an alias to /src
import Navigation from '@/components/Navigation.vue'

export default {
  name: 'HomeView',
  components: {
    Navigation
  }
}
</script>

  