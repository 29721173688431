<template>
 
    <Main/>
    <Services/>
    <Details/>
    <Partner/>
    <Footer/>
</template>

<script>
// @ is an alias to /src
import Main from '@/components/Main.vue'
import Services from '@/components/Services.vue'
import Details from '@/components/Details.vue'

import Footer from '@/components/Footer.vue'

export default {
  name: 'MainView',
  components: {
    Main,Services,Details,Footer
  }
}
</script>
